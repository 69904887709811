<template>
  <!-- star -->

  <div class="homepage_wrap" ref="head">
    <nav2 />
    <!-- banner图和导航栏 -->
    <div class="banner_nav">
      <!-- banner -->
      <div class="banner_nav_bg">
        <!-- src="../assets/img/login-bg.png" -->
        <img v-lazy="require('../assets/img/login-bg.png')" alt="" style="width:1920px;height: 800px;" lazy />
      </div>
      <!-- title 图片文字区域-->
      <div class="banner_title_wrap">
        <div class="banner_title_center">
          <p>科技让出行更美好</p>
          <div>让共享出行更安全、更智能、更潮流！</div>
        </div>
      </div>
    </div>
    <div class="product">
      <div class="product_content">
        <h1>产品介绍</h1>
        <div class="product_item">
          <div v-for="(item,index) in productList" :key="index" @click="jumpProduct(item.command)">
            <img class="product_item_img" v-lazy="item.img" alt="" />
            <p class="product_item_txt">{{item.txt}}</p>
          </div>
        </div>
      </div>

    </div>
    <!-- 关于企业 -->
    <div class="AboutEnterprises">
      <div class="AboutEnterprises_cont" style="margin-top: 30px;border: 0px solid skyblue;">
        <!-- top -->
        <div class="AboutEnterprises_cont_top" style="display: flex;margin-top: 120px;
        ">
          <!-- top_left -->
          <div class="AboutEnterprises_cont_top_left">
            <h1 style="margin-top: 50px;">关于企业</h1>
            <p>
              杭州小潮出行科技有限公司（以下简称小潮出行）坐落于浙江省杭州市西湖区，是中国飞神集团控股的子公司。公司主要立足于两轮出行做科技服务和运营服务，是一家具有研发、销售、投放运营等综合能力的现代化企业。主要业务为城市、景区、校园等场景的共享出行服务。
              小潮出行在2022年开始致力于氢能源在共享出行和微交通领域的落地研究，公司秉承以“以人为本，科技让出行更美好”为企业发展理念，诚邀国内外科研人才加入，不断丰富氢能源出行行业的技术落地解决方案。目前在智能IOT产品、氢燃料电池、出行SASS系统上均投入了大量人力物力进行研发。小潮的使命和愿景是：给用户带来真正满意的智能化出行服务。
            </p>
          </div>
          <!-- top_right -->
          <div class="AboutEnterprises_cont_top_right">
            <img v-lazy="require('../assets/img/xcbg.png')" alt="" style="width: 588px;height: 353px;margin-top: 130px;margin-left: -50px;" />
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="CooperativePartner">
      <div class="CooperativePartner_cont">
        <h1 class="CooperativePartner_cont_title">服务案例</h1>
      </div>
      <div class="CooperativePartner_cont">
        <el-carousel :interval="4000" type="card" height="230px" arrow="always" indicator-position="outside">
          <el-carousel-item v-for="(item, index) in logoList" :key="index">
            <div class="card_item" @click="ServersDetails(item)">
              <img v-lazy="`${item.coverImg}`" alt="" lazy class="imgss" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

    </div>
    <!-- 最新资讯 -->
    <div class="news">
      <div class="item2">
        <div class="item2_cont">
          <h1>新闻资讯</h1>
          <div class="item2_cont1" v-for="(item, index) in newList" :key="index" @click="NewsDetails(item)">
            <img v-lazy="`${item.coverImg}`" alt="" lazy class="imgss" />
            <div class="item2_cont1_text">
              <div class="item2_cont1_text_top">
                <h1>
                  {{ item.title }}
                </h1>
                <p>
                  {{ item.secondTitle }}
                </p>
              </div>
              <div class="item2_cont1_text_btm">
                <div>{{item.createTime}}</div>
                <p>查看更多></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="zixun">
      <div class="zixun_button" @click="toLianxi">立即咨询</div>
    </div>
    <!-- 集团产业 -->
    <!-- <div class="GroupIndustry">
      <div class="GroupIndustry_cont">
        <div class="GroupIndustry_cont_left">

          <div class="GroupIndustry_cont_left_title">
            <p>集团产业</p>
          </div>
          <div class="GroupIndustry_cont_left_img_f">
            <div
              v-for="(item, index) in jituanList"
              :key="index"
              class="GroupIndustry_cont_left_img"
            >
              <img :src="item.jituanimg" />
              <img
                :src="item.jituanimgyinying"
                class="GroupIndustry_cont_left_img2"
              />
              <div class="GroupIndustry_cont_left_text">
                <span>{{ item.jituanspan }}</span>
                <p>{{ item.jituanp }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="GroupIndustry_cont_right">
          <p class="GroupIndustry_cont_right_title">更多></p>
          <div class="GroupIndustry_cont_right_img">
            <img src="../assets/img/jituanyou.png" alt="" />
          </div>
          <div class="GroupIndustry_cont_right_text">
            <p>执教寒夜</p>
            <span>校企共建专业 | 校企共建二级学院 | 校企共建产业学院</span>
          </div>
        </div>
      </div>
    </div> -->

    <!-- footer -->
    <footer2></footer2>

    <!-- end -->
  </div>
</template>


<script>
import { newsList, serversList } from '../api/index'
import nav2 from "../components/nav2.vue";
import footer2 from "../components/footer.vue";
export default {
  name: "index",
  components: {
    footer2, nav2
  },
  data () {
    return {
      navList: [
        { title: "首页", url: "/" },
        // { title: "产品介绍", url: "/news" },
        // {},
        { title: "公司介绍", url: "/C5" },
        { title: "公司介绍", url: "/news" },
        { title: "联系方式", url: "/lianxi" },
      ],
      productList: [
        {
          img: require("../assets/img/home/product1.png"),
          txt: '电动滑板车-C5',
          command: "a"
        },
        {
          img: require("../assets/img/home/product2.png"),
          txt: '电动滑板车-C7',
          command: "b"
        },
        {
          img: require("../assets/img/home/product3.png"),
          txt: '两轮电动车',
          command: "c"
        },
        {
          img: require("../assets/img/home/product4.png"),
          txt: '智能中控',
          command: "d"
        },
      ],
      logoList: [
        { logo: require("../assets/img/c1.png") },
        { logo: require("../assets/img/c2.png") },
        { logo: require("../assets/img/c3.png") },
        { logo: require("../assets/img/c4.png") },
        { logo: require("../assets/img/c5.png") },
        { logo: require("../assets/img/c6.png") },
        { logo: require("../assets/img/c7.png") },
        { logo: require("../assets/img/c8.png") },
        { logo: require("../assets/img/c9.png") },
        { logo: require("../assets/img/c10.png") },
      ],
      newList: [],
     
    };
  },
  computed: {
    title () {
      return this.$store.state.title;
    },
  },
  mounted () {
    this.getNewsList()
    this.getServersList()
  },
  methods: {
    funyuming (msg) {
      this.$router.push(msg);
    },
    jumpProduct (command) {
      switch (command) {
        case 'a':
          this.$router.push("/C5");
          break;
        case 'b':
          this.$router.push("/C7");
          break;
        case 'c':
          this.$router.push("/Bike");
          break;
        case 'd':
          this.$router.push("/Iot");
          break;
      }
    },
    toLianxi () {
      this.$router.push("/lianxi");
    },
    getNewsList () {
      let params = {
        pageNum: 1,
        pageSize: 3
      }
      newsList(params).then((res) => {
        this.newList = res.rows
      })
    },
    getServersList () {
      let params = {
        pageNum: 1,
        pageSize: 4
      }
      serversList(params).then((res) => {
        this.logoList = res.rows
      })
    },
    ServersDetails (val) {
      this.$router.push({ path: `/productDetail/${val.id}` });
    },
    NewsDetails (val) {
      this.$router.push({ path: `/newsxiangqing/${val.id}` });
    },
  },
};
</script>
<style scoped>
@charset "utf-8";

/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}

/*去内外边距*/
html,
body {
  font-family: '微软雅黑', '宋体', Arail, Tabhoma;
  text-align: left;
}

/*字体 文本居左*/
ul {
  list-style: none;
}

/*去ul li 小点*/
img {
  border: 0 none;
}

/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}

/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}

/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}

/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}

/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
}

/*清浮动*/
a {
  color: #000000;
}

/*==========================Reset_End===========================*/

.homepage_wrap {
  width: 100%;
  /* width: 1920px; */
  margin: 0 auto;
  height: 1000px;
}

.banner_nav {
  width: 100%;
  /* width: 1920px; */
  height: 800px;
  position: relative;
}

.banner_nav_bg img {
  width: 1920px;
  /* 文本居顶部对齐 */
  vertical-align: top;
}

/* 导航部分 */

.navWrap {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /*  */
  padding-top: 40px;
}

.navWrap_center {
  width: 90%;
  /* width: 1440px; */
  height: 101px;
  margin: 0 auto;
  background: #fff;
  border-radius: 51px;
  /*  */
  display: flex;
  justify-content: space-between;
}

.navWrap_center_img {
  width: 181px;
  height: 54px;
  margin: 23px 0 0 221px;
}

.navWrap_center_img img {
  width: 181px;
}

.navWrap_center_ul {
  /* border:1px solid red; */
  margin-right: 170px;
  /* margin-right: 261px; */
}
/* .navWrap_center_ul li:nth-child(1){
  display: inline-block;
  height: 98px;
  line-height: 98px;
  font-size: 24px;
  border: 1px solid black;
  border-bottom: 4px solid blue;
  margin-left: 260px;
  padding: 0 5px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 22px;
} */
.navWrap_center_ul li {
  display: inline-block;
  width: 120px;
  height: 98px;
  text-align: center;
  line-height: 98px;
  /* border-bottom: 4px solid blue; */
  margin-left: 50px;
  padding: 0 5px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  /* line-height: 22px; */
}

.navWrap_center_ul li:hover {
  cursor: pointer;
  color: rgba(35, 51, 127, 1);
  border-bottom: 2px solid rgba(35, 51, 127, 1);
}

/* title */

.banner_title_wrap {
  width: 100%;
  position: absolute;
  bottom: 290px;
  /* border: 1px solid yellow; */
}

.banner_title_center {
  /* border: 1px solid white; */
  text-align: center;
  width: 1200px;
  margin: 0 auto;
  font-size: 90px;
  font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
  font-weight: bold;
  color: #ffffff;
  line-height: 124px;
  letter-spacing: 1px;
}

.banner_title_center div {
  padding-top: 14px;
  font-size: 20px;
  font-family: Helvetica;
  line-height: 24px;
  letter-spacing: 4px;
}

.banner_title_center div span {
  margin: 0 18px;
}

/* news */
.news {
  width: 100%;
  /* width: 1920px; */
  /* height: 801px; */
  background: #f8f8f8;
}
.item2_cont {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 30px;
}
.item2_cont1 {
  margin-top: 30px;
  display: flex;
  width: 1200px;
  height: 252px;
  cursor: pointer;
  /* background: #f8f8f8; */
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.11);
}

.item2_cont1 img {
  margin: 32px 48px 32px 32px;
  width: 300px;
  height: 188px;
  /* border: dashed; */
}
.item2_cont1_text {
  width: 868px;
}
.item2_cont1_text_top {
  margin: 45px 0 16px 0;
  width: 732px;
  height: 112px;

  border-bottom: 1px solid #eaeaea;
}
.item2_cont1_text_top > h1 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;

  color: #3f3f3f;
  line-height: 24px;
}
.item2_cont1_text_top p {
  margin-top: 16px;
  width: 732px;
  height: 56px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}
.item2_cont1_text_btm {
  width: 732px;

  display: flex;
  justify-content: space-between;
}
.item2_cont1_text_btm div {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;

  color: #999999;
  line-height: 28px;
}
.item2_cont1_text_btm p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #23337f;
  line-height: 28px;
}

.zixun {
  height: 300px;
  background: #5374fa;
  position: relative;
}
.zixun_button {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 300px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 32px;
  color: #fff;
  border: 2px solid #879acc;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

/* 集团产业 */
.GroupIndustry {
  width: 1920px;
  height: 800px;
  background: #ffffff;
}

.GroupIndustry_cont {
  width: 1200px;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
}

.GroupIndustry_cont_left_title {
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #444444;
  line-height: 50px;
  margin: 84px 0 40px 0;
}

.GroupIndustry_cont_left_img_f {
  width: 793px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.GroupIndustry_cont_left_img {
  margin-bottom: 24px;

  position: relative;
}

.GroupIndustry_cont_left_img img:first-child {
  width: 384px;
  height: 261px;
  background: linear-gradient(180deg, rgba(0, 100, 255, 0) 0%, #000b6e 100%);
}

.GroupIndustry_cont_left_img2 {
  position: absolute;
  left: 0;
  bottom: 0;
}

.GroupIndustry_cont_left_text {
  position: absolute;
  left: 32px;
  bottom: 18px;
}

.GroupIndustry_cont_left_text p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  margin-top: 10px;
}

.GroupIndustry_cont_left_text span {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}

.GroupIndustry_cont_right {
  position: relative;
  margin-top: 98px;
}

.GroupIndustry_cont_right_title {
  float: right;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 22px;
  margin-bottom: 54px;
}

.GroupIndustry_cont_right_img {
  width: 384px;
  height: 546px;
}

.GroupIndustry_cont_right_img2 {
  position: absolute;
  bottom: 24px;
}

.GroupIndustry_cont_right_text {
  position: absolute;
  left: 45px;
  bottom: 45px;
}

.GroupIndustry_cont_right_text p {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 33px;
}

.GroupIndustry_cont_right_text span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}

/* 关于企业 */
.AboutEnterprises {
  width: 100%;
  /* width: 1920px; */
  height: 856px;
  background-image: url('../assets/img/guanyu_bg.png');
}

.AboutEnterprises_cont {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
}

.AboutEnterprises_cont_top {
  display: flex;
  justify-content: space-between;
}

.AboutEnterprises_cont_top_left {
  margin-top: 143px;
  width: 600px;
}

.AboutEnterprises_cont_top_left > h1 {
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 50px;
}

.AboutEnterprises_cont_top_left p {
  margin-top: 36px;

  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
}

.AboutEnterprises_cont_top_left_btn {
  width: 160px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  margin-top: 81px;
  display: flex;
  align-items: center;
}

.AboutEnterprises_cont_top_left_btn img {
  width: 24px;
  height: 24px;

  margin-left: 8px;
}

.AboutEnterprises_cont_top_left_btn span {
  margin-left: 32px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
}

.AboutEnterprises_cont_top_right {
  position: relative;
  width: 487px;
  height: 517px;
  margin-top: 115px;
}

.AboutEnterprises_cont_top_right img {
  position: absolute;
  z-index: 1;
}

.AboutEnterprises_cont_top_right Div {
  width: 317px;
  height: 408px;
  background: #3491e5;
  position: absolute;
  top: 64px;
  left: -33px;
}

.AboutEnterprises_cont_bottom {
  display: flex;

  margin-top: 80px;
}

.AboutEnterprises_cont_bottom_item {
  margin-right: 70px;
  position: relative;
  width: 247px;
  border-right: 1px solid #ffffff;
  display: flex;
}

.AboutEnterprises_cont_bottom_item3 {
  margin-right: 70px;
  position: relative;
  width: 323px;
  border-right: 1px solid #ffffff;
  display: flex;
}

.AboutEnterprises_cont_bottom_item3 p {
  width: 86px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #b30588;
  line-height: 28px;
}

.AboutEnterprises_cont_bottom_item3 h1 {
  font-size: 60px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 84px;
}

.AboutEnterprises_cont_bottom_item3 span {
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #692020;
  line-height: 24px;
}

.AboutEnterprises_cont_bottom_item4 {
  position: absolute;
  left: 161px;
  bottom: 15px;
}

.AboutEnterprises_cont_bottom_item h1 {
  font-size: 60px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 84px;
}

.AboutEnterprises_cont_bottom_item span {
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #692020;
  line-height: 24px;
}

.AboutEnterprises_cont_bottom_item p {
  width: 86px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #b30588;
  line-height: 28px;
}

.AboutEnterprises_cont_bottom_item2 {
  position: absolute;
  left: 85px;
  bottom: 15px;
}
.product {
  width: 100%;
  height: 301px;
  background: #f8f8f8;
  margin-top: 80px;
}
.product_content {
  width: 1200px;
  margin: 0 auto;
}
.product_item {
  display: flex;
  justify-content: space-between;
}
.product_item_img {
  width: 264px;
  height: 264px;
  cursor: pointer;
}
.product_item_txt {
  text-align: center;
  font-size: 28px;
}
/* 合作伙伴 */
.CooperativePartner {
  width: 100%;
  /* width: 1920px; */
  /* height: 430px; */
  /* background: #2121E9 !important;
  border: 2px solid skyblue; */
  background-image: url('../assets/img/hezuo_bg.png');
}

.CooperativePartner_cont {
  width: 1200px;
  margin: 0 auto;
}

.CooperativePartner_cont_title {
  text-align: center;
  margin-top: 80px;
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #444444;
  line-height: 50px;
}
.card_item {
  /* border: 1px solid #afafaf; */
  cursor: pointer;
  padding-bottom: 20px;
  margin: 0 20px 40px 0;
  text-align: center;
  /* width: 350px; */
}
.imgss {
  margin: 20px;
  width: 300px;
  height: 188px;
}
::v-deep .el-carousel__arrow {
  background-color: rgb(20 111 215);
}
::v-deep .el-carousel__indicators--outside button {
  background-color: rgb(20 111 215);
}
</style>


